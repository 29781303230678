<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="modal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Account Edit</h4>
      </div>
      <form [formGroup]="form" (ngSubmit)="save()" novalidate>
        <div class="modal-body">
          <!--<p><b>Company:</b> {{ account.name }} </p>-->

          <label class="col-xs-6" [ngClass]="{'has-error': !form.controls.contactName.valid }">
            <span [innerHtml]="'views.account-edit.contactName' | translate"></span>
            <input type="text" class="form-control input-sm" formControlName="contactName" [disabled]="isProcessing" />
            <div *ngIf="(!form.controls.contactName.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph">
              <div class="error-tooltip">
                <p *ngIf="form.controls.contactName.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                <p *ngIf="form.controls.contactName.errors?.maxlength">{{ 'common.MustBeLessThanChars' | translate: {maxChars: form.controls.contactName.errors?.maxlength.requiredLength} }}</p>
                <p *ngIf="form.controls.contactName.errors?.minlength">{{ 'common.MustBeMoreThanChars' | translate:{minchars: form.controls.contactName.errors?.minlength.requiredLength} }}</p>
              </div>
            </div>
          </label>

          <label class="col-xs-6" [ngClass]="{'has-error': !form.controls.contactEmail.valid }">
            <span [innerHtml]="'views.account-edit.contactEmail' | translate"></span>
            <input type="text" class="form-control input-sm" formControlName="contactEmail" [disabled]="isProcessing" />
            <div *ngIf="(!form.controls.contactEmail.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph">
              <div class="error-tooltip">
                <p *ngIf="form.controls.contactEmail.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                <p *ngIf="form.controls.contactEmail.errors?.maxlength">{{ 'common.MustBeLessThanChars' | translate: {maxChars: form.controls.contactEmail.errors?.maxlength.requiredLength} }}</p>
                <p *ngIf="form.controls.contactEmail.errors?.minlength">{{ 'common.MustBeMoreThanChars' | translate:{minchars: form.controls.contactEmail.errors?.minlength.requiredLength} }}</p>
                <p *ngIf="form.controls.contactEmail.errors?.alkValidEmailAddress">{{ 'common.InvalidEmailAddress' | translate }}</p>
              </div>
            </div>
          </label>

          <label class="col-xs-6" [ngClass]="{'has-error': !form.controls.contactPhone.valid }">
            <span [innerHtml]="'views.account-edit.contactPhone' | translate"></span>
            <input type="text" class="form-control input-sm" formControlName="contactPhone" [disabled]="isProcessing" />
            <div *ngIf="(!form.controls.contactPhone.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph">
              <div class="error-tooltip">
                <p *ngIf="form.controls.contactPhone.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                <p *ngIf="form.controls.contactPhone.errors?.maxlength">{{ 'common.MustBeLessThanChars' | translate: {maxChars: form.controls.contactPhone.errors?.maxlength.requiredLength} }}</p>
                <p *ngIf="form.controls.contactPhone.errors?.minlength">{{ 'common.MustBeMoreThanChars' | translate:{minchars: form.controls.contactPhone.errors?.minlength.requiredLength} }}</p>
              </div>
            </div>
          </label>

          <label *ngIf = "isRouteReporterAccount"  class="col-xs-6" [ngClass]="{'has-error': !form.controls.numberOfWeeksToSaveData.valid }">
            <span [innerHtml]="'views.account.WeeksToSaveData' | translate"></span>
            <input type="number" step="1" class="form-control input-sm" formControlName="numberOfWeeksToSaveData" [disabled]="isProcessing" />
            <div *ngIf="(!form.controls.numberOfWeeksToSaveData.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph">
              <div class="error-tooltip">
                <p *ngIf="form.controls.numberOfWeeksToSaveData.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                <p *ngIf="form.controls.numberOfWeeksToSaveData.errors?.maxlength">{{ 'common.MustBeLessThanChars' | translate: {maxChars: form.controls.numberOfWeeksToSaveData.errors?.maxlength.requiredLength} }}</p>
                <p *ngIf="form.controls.numberOfWeeksToSaveData.errors?.minlength">{{ 'common.MustBeMoreThanChars' | translate:{minchars: form.controls.numberOfWeeksToSaveData.errors?.minlength.requiredLength} }}</p>
                <p *ngIf="form.controls.numberOfWeeksToSaveData.errors?.validData">{{ 'common.FallsBetween' | translate: {min: form.controls.numberOfWeeksToSaveData.errors.validData.min, max: form.controls.numberOfWeeksToSaveData.errors.validData.max} }}</p>
                <p *ngIf="form.controls.numberOfWeeksToSaveData.errors?.isNotValidInteger">{{ 'common.InvalidInteger' | translate }}</p>
              </div>
            </div>
          </label>

          <label class="share-data-modal-label col-xs-12">
            <input type="checkbox" formControlName="shareDataAnonymously" [disabled]="isProcessing" />
            <span class="share-data-modal-text" [innerHtml]="'views.company-administration.ShareDataAnonymously' | translate"></span>
            <span class="ico ico-info-circle" tooltip="{{'views.company-administration.ShareDataTooltip' | translate}}"></span>
          </label>

        </div>
        <div class="clearfix"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default cancel-btn" (click)="modal.hide()">{{ 'common.Cancel' | translate }}</button>
          <button type="submit" class="btn btn-default" [disabled]="isProcessing || !form.valid">
            <i *ngIf="!form.valid" class="glyphicon glyphicon-exclamation-sign error-glyph"></i>
            <span [innerHtml]="'common.Save' | translate"></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<confirmation-modal #confirmModal title="{{'views.company-administration.DisableTripDataSharing' | translate}}" body="{{'views.company-administration.DisableTripDataWarning' | translate}}"></confirmation-modal>
